import React from 'react';
import i18n from 'i18n';
import { useQuery } from '@tanstack/react-query';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef, deDE, GridRowsProp } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  '& .Mui-error': {
    color: theme.palette.error.main,
  },
}));

const currentYear = new Date().getFullYear();
const reportingYears = Array.from({ length: 11 }, (x, i) => currentYear - i);

export default function EmployeeCountTable({
  data,
  variable,
  unit,
}: {
  data: GridRowsProp;
  variable: string;
  unit?: string;
}) {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const [rows, setRows] = React.useState(data);

  const currenciesValues = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['companies-currencies'],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/currencies`,
        auth.requestConfig
      );

      return res.data as string[];
    },
  });

  React.useEffect(() => {
    setRows(data);
  }, [setRows, data]);

  const columns: GridColDef[] = [
    {
      field: 'reporting_year',
      headerName: t('Year'),
      flex: 0,
      sortable: true,
      editable: true,
      type: 'singleSelect',
      valueOptions: reportingYears,
    },
    {
      field: 'value',
      headerName: `${t('Value')}${unit ? ` (${unit})` : ''}`,
      flex: 1,
      sortable: true,
      editable: true,
      type: 'number',
      renderCell: (params) => {
        if (params.colDef.headerName === 'Value (year)') {
          return params.value;
        } else {
          return (
            <div>
              {Number(params.value)
                ? new Intl.NumberFormat(i18n.language).format(
                    Number(params.value)
                  )
                : params.value}
            </div>
          );
        }
      },
    },
  ];

  const columnsForTurnoverCurrency: GridColDef[] = [
    {
      field: 'reporting_year',
      headerName: t('Year'),
      flex: 0,
      sortable: true,
      editable: true,
      type: 'singleSelect',
      valueOptions: reportingYears,
    },
    {
      field: 'value',
      headerName: t('Value'),
      flex: 1,
      editable: true,
      type: 'singleSelect',
      valueOptions: currenciesValues.data,
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('No data'),
  };

  return !data ? (
    <EmptyTextField text={t('No data') + ''} />
  ) : (
    <StyledBox>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'reporting_year', sort: 'desc' }],
          },
        }}
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={
          !currenciesValues.isError && variable === 'TURNOVER_CURRENCY'
            ? columnsForTurnoverCurrency
            : columns
        }
        isCellEditable={() => false}
        autoHeight
        hideFooter
      />
    </StyledBox>
  );
}
