import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import useCPASurvey from '../store/useCPASurvey';

import Grid from 'components/common/Grid';
import Input from 'components/common/Input';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  answers: {
    fontWeight: 'bold',
    flex: '1 0 100%',
    minHeight: '20px',
  },
  possibleAnswers: {
    flex: '1 0 100%',
    opacity: 0.5,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const CPASurveyQuestionData = ({
  id,
  flag,
  values,
  answers,
  cpasurveyId,
  index,
  url,
}) => {
  const { notes } = useCPASurvey();
  const { t } = useTranslation('common');
  const classes = useStyles();
  const note = notes.get(id);

  return (
    <Grid container spacing={2}>
      {answers && answers.length > 0 && (
        <Grid item xs={6}>
          <div className={classes.flex}>
            {answers.map((value, i) => {
              return (
                <div key={i} className={classes.possibleAnswers}>
                  {value || ' '}
                </div>
              );
            })}
          </div>
        </Grid>
      )}
      <Grid item xs={6}>
        <div className={classes.flex}>
          {url ? (
            <a href={url}>{values.file_name}</a>
          ) : (
            <React.Fragment>
              {values.map((value, i) => {
                return (
                  <div key={i} className={classes.answers}>
                    <span>{value}</span>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      </Grid>
      {note && (
        <Grid item xs={12}>
          <Input
            fullWidth
            multiline
            rows="5"
            label={t('Review Notes')}
            name="note"
            value={note}
            disabled
          />
        </Grid>
      )}
    </Grid>
  );
};
export default CPASurveyQuestionData;
