import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowSelectionModel,
  deDE,
} from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';

import useSearchStore from 'components/App/Routes/Admin/Search/store/useStore';
import { useNavigate } from 'react-router-dom';
import { getCountryName } from 'utils/getCountryName';
import { observer } from 'mobx-react-lite';
import { EditIcon } from 'components/icons/mui_icons';
import MergeIcon from '@mui/icons-material/Merge';

const SearchCompanyResultsTable = ({ data }: { data: any[] }) => {
  const { t } = useTranslation('common');
  const { setEditCompanyId, setSelectedCompany } = useSearchStore();
  const navigate = useNavigate();
  const rows = data.slice();
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('Name'),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div
          title={params.row?.name}
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {params.row?.name}
        </div>
      ),
    },
    {
      field: 'initial_source',
      headerName: t('Source'),
      width: 80,
      sortable: false,
    },
    {
      field: 'lei_code',
      headerName: t('Company LEI code'),
      flex: 0,
      minWidth: 210,
      sortable: false,
    },
    {
      field: 'country',
      headerName: t('Country'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          title={getCountryName(params.row?.country)}
        >
          {getCountryName(params.row?.country)}
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 40,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            showInMenu
            icon={<EditIcon />}
            disabled
            label={t('Edit')}
            onClick={() => setEditCompanyId(id + '')}
          />,
          <GridActionsCellItem
            showInMenu
            icon={<MergeIcon />}
            disabled
            label={t('Select for merge')}
            onClick={() => navigate(`/admin/merge/${id}`)}
          />,
        ];
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('No results'),
  };
  return !data ? (
    <EmptyTextField text={t('No results') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          const selectedCompany = data.find(
            (company) => company.id === newRowSelectionModel[0]
          );
          setSelectedCompany(selectedCompany);
          setRowSelectionModel(newRowSelectionModel);
        }}
        hideFooter
      />
    </div>
  );
};

export default observer(SearchCompanyResultsTable);
