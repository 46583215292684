import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';

export default function CDPTable({ data }: { data: any }) {
  const { t } = useTranslation('common');

  const rows = data.map((datapoints: any) => {
    return { id: Math.random(), ...datapoints };
  });

  const columns: GridColDef[] = [
    {
      field: 'year',
      headerName: t('Year'),
      flex: 0,
      sortable: false,
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 0,
      minWidth: 150,
      sortable: false,
    },
    {
      field: 'score',
      headerName: t('Score'),
      flex: 0,
      sortable: false,
    },
  ];

  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'year', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        hideFooter={rows.length <= 10}
      />
    </div>
  );
}
