import React from 'react';
import { observer } from 'mobx-react-lite';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import FlagIcon from '@mui/icons-material/Flag';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import Grid from 'components/common/Grid';
import CPASurveyQuestionSwitch from './CPASurveyQuestionSwitch';

import axios from 'axios';
import useCPASurvey from '../store/useCPASurvey';
import { useQuery } from '@tanstack/react-query';
import useAuth from 'hooks/useAuth';

import Loading from 'components/common/Loading';

import api from 'constants/api';

const useStyles = makeStyles((theme) => ({
  container: {
    border: ({ flag, final }) =>
      `1px solid ${
        flag && !final ? theme.palette.error.main : theme.palette.divider
      }`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  answers: {
    fontWeight: 'bold',
    flex: '1 0 100%',
    minHeight: '20px',
  },
  possibleAnswers: {
    flex: '1 0 100%',
    opacity: 0.5,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const CPASurveyQuestion = ({
  id,
  title,
  mediaid,
  values,
  answers,
  cpasurveyId,
  index,
  onFlagClick,
  final,
}) => {
  const auth = useAuth();
  const [open, setOpen] = React.useState(index === 0 ? true : false);
  const { flags } = useCPASurvey();
  const classes = useStyles({ flag: flags.get(id), final });

  const { isFetching, data } = useQuery({
    enabled: !!mediaid,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 10000,
    staleTime: 10000,
    queryKey: [`cpa-media-${mediaid}`],
    queryFn: async () => {
      if (!mediaid) return {};
      const res = await axios.get(
        `${api.url}/admin/crc_surveys/media/${mediaid}/`,
        auth.requestConfig
      );
      return res.data;
    },
  });
  const url = mediaid && data ? data.url : '';
  const flag = flags.get(id);
  const displayText =
    mediaid && data
      ? data.file_name
      : values.filter((v) => v !== '').join(', ');

  return isFetching ? (
    <Loading />
  ) : (
    <div className={classes.container}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ flex: '1 1' }}>{title}</div>
            {!final && (
              <div className={flag ? classes.error : undefined}>
                <IconButton size="small" onClick={onFlagClick}>
                  <FlagIcon color={flag ? 'error' : 'inherit'} />
                </IconButton>
              </div>
            )}
            <div>
              <IconButton
                size="small"
                onClick={(e) => {
                  setOpen(!open);
                }}
              >
                {open ? (
                  <ExpandLessIcon color={'inherit'} />
                ) : (
                  <ExpandMoreIcon color={'inherit'} />
                )}
              </IconButton>
            </div>
          </div>
        </Grid>

        {!open && !!(url || displayText) && (
          <Grid item>
            <div style={{ flex: '1 1 100%', fontWeight: 'bold' }}>
              {url ? (
                <a href={url} target="_blank" rel="noreferrer">
                  {displayText}
                </a>
              ) : (
                <span>{displayText}</span>
              )}
            </div>
          </Grid>
        )}

        {open && (
          <Grid item xs={12}>
            <CPASurveyQuestionSwitch
              id={id}
              values={values}
              answers={answers}
              cpasurveyId={cpasurveyId}
              final={final}
              index={index}
              url={url}
              flag={flag}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default observer(CPASurveyQuestion);
