import Container from 'components/common/Container';

import Stack from 'components/common/Stack';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import { AttachIcon, CloseIcon } from 'components/icons/mui_icons';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import TccInputTextField from 'components/common/TccInputTextField';
import Typography from 'components/common/Typography';
import IconButton from 'components/common/IconButton';
import Box from 'components/common/Box';
import LinearProgress from 'components/common/LinearProgress';

const UploadButton = ({
  disabled,
  files = null,
  setFiles,
  setUploadComplete,
}: {
  disabled?: boolean;
  files: FileList | null;
  setFiles: (files: FileList | null) => void;
  setUploadComplete: (complete: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [filesError, setFilesError] = useState(false);
  return (
    <>
      <label htmlFor="files-upload">
        <input
          hidden
          type="file"
          id="files-upload"
          multiple
          onChange={(e) => {
            setFiles(e.target.files);
            const maxSize = 50000000; // 50MB
            let size = 0;
            if (e.target.files) {
              for (let file of Array.from(e.target.files)) {
                size += file.size;
              }
            }
            if (size > maxSize) {
              setFilesError(true);
            } else {
              setFilesError(false);
              setUploadComplete(false);
            }
          }}
        />
        <Button
          variant="outlined"
          color="inherit"
          component="span"
          disabled={disabled}
          startIcon={<AttachIcon />}
        >
          {`${t('Upload file')} ${!!files ? `(${files.length})` : ''}`}
        </Button>
      </label>
      {files && (
        <Stack
          mt={2}
          mb={2}
          direction={'row'}
          spacing={2}
          alignItems={'center'}
        >
          <IconButton onClick={() => setFiles(null)}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" noWrap>
            {Array.from(files)[0].name}
          </Typography>
        </Stack>
      )}
      {filesError ? (
        <Alert severity="error">
          {t('Maximum attachment size is {{size}}', { size: '50MB' })}
        </Alert>
      ) : null}
    </>
  );
};

function DataServicePDFtoMARKDOWN() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { companyId } = useParams();
  const [reportingYear, setReportingYear] = useState('');
  const [disclosureYear, setDisclosureYear] = useState('');
  const [originalUrl, setOriginalUrl] = useState('');
  const [files, setFiles] = useState<null | FileList>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.set('type', 'PDF_TO_MARKDOWN');
      if (companyId) {
        data.set('company', companyId);
      }
      data.set('reporting_year', reportingYear);
      data.set('disclosure_year', disclosureYear);
      data.set('original_url', originalUrl);
      if (files) {
        data.set('file', Array.from(files)[0]);
      }
      await axios.post(`${api.url}/etl/jobs/create_with_s3asset/`, data, {
        ...auth.requestConfig,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          if (totalLength) {
            setUploadProgress(
              Math.round((progressEvent.loaded * 100) / totalLength)
            );
          }
        },
      });
      navigate('../..', { relative: 'path' });
    } catch (err) {
      // enable once live on backend:
      // window.Sentry?.captureException(err);
      console.error(err);
    }
  };

  return companyId ? (
    <Container disableGutters maxWidth="xs">
      <form onSubmit={onSubmit}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography sx={{ pt: 4 }} variant="h4">
              PDF Upload(Markdown)
            </Typography>
            <Alert severity="info">
              {t(
                'PDF goes in 👉 markdown assets ready for further processing come out.'
              )}
            </Alert>
          </Stack>
          <Box />
          <UploadButton
            files={files}
            setFiles={setFiles}
            setUploadComplete={setUploadComplete}
          />
          {files && uploadProgress > 0 && (
            <LinearProgress variant="determinate" value={uploadProgress} />
          )}
          <Box />

          <TccInputTextField
            required
            title={t(`Disclosure Year`)}
            placeholder={t(`Disclosure Year`)}
            subTitle={t(
              'The Disclosure Year should be the year that the pdf was published.'
            )}
            value={disclosureYear}
            onChange={(e) => setDisclosureYear(e.target.value)}
          />
          <TccInputTextField
            title={t(`Fallback Reporting Year (optional)`)}
            placeholder={t(`Fallback Reporting Year`)}
            subTitle={t('Tag this asset with a fallback reporting year.')}
            value={reportingYear}
            onChange={(e) => setReportingYear(e.target.value)}
          />
          <TccInputTextField
            title={t(`Original URL (optional)`)}
            placeholder={'https://...'}
            subTitle={t('Link to where this document can be found')}
            value={originalUrl}
            onChange={(e) => setOriginalUrl(e.target.value)}
          />
          <Box />
          <Button variant="contained" color={'primary'} type="submit">
            Run
          </Button>
        </Stack>
      </form>
    </Container>
  ) : (
    <div>no company state</div>
  );
}

export default DataServicePDFtoMARKDOWN;
