import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Container from 'components/common/Container';

import Stack from 'components/common/Stack';
import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import CompanyDataServicesList from './CompanyDataServicesList';
import Button from 'components/common/Button';
import { UploadIcon } from 'components/icons/mui_icons';
import { useNavigate } from 'react-router-dom';

function CompanyDataServices({
  company,
}: {
  company: { [key: string]: string };
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Container disableGutters maxWidth="xl">
      <Stack spacing={2} direction={'row'} flexWrap={'wrap'}>
        <Button
          startIcon={<UploadIcon />}
          onClick={() => navigate('services/cdp')}
          variant="contained"
        >
          {t('Upload CDP')}
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('services/html-harvester')}
        >
          {t('New Html Harvester')}
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('services/pdf-to-text')}
        >
          {t('PDF to text')}
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('services/pdf-to-markdown')}
        >
          {t('PDF to markdown')}
        </Button>
        <Button
          variant="contained"
          onClick={() => navigate('services/content-to-unchecked-data')}
        >
          {t('Content to unchecked data')}
        </Button>
      </Stack>
    </Container>
  );
}

export default CompanyDataServices;
