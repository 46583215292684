import { decorate, observable, action, runInAction } from 'mobx';
import api from 'constants/api';
import axios from 'axios';
class UserDashboardCustomersStore {
  // OBSERVABLES................................................................
  isLoadingDashboardDataStatistics = false;
  hasLoadedDashboardDataStatistics = false;
  isLoadingDashboardData = false;
  hasLoadedDashboardData = false;
  user_company_customers_count_on_search = null;
  user_company_customers_results = [];
  user_company_customers_with_valid_crc = 0;

  // COMPUTEDS..................................................................
  // ACTIONS....................................................................

  loadDashboardData = async ({ auth, page, search, rowsPerPage }) => {
    let params = new URLSearchParams();
    runInAction(() => (this.isLoadingDashboardData = true));

    params.append('page', page);
    params.append('search', search);
    params.append('page-size', rowsPerPage);
    try {
      let user_company_customers_call = axios.get(
        `${api.url}/user/company/customers/`,
        Object.assign(auth.requestConfig, { params })
      );
      const results = await user_company_customers_call;
      runInAction(
        () => (this.user_company_customers_count_on_search = results.data.count)
      );
      runInAction(
        () => (this.user_company_customers_results = results.data.results)
      );
    } catch (err) {
      this.loadDashboardDataFailure(err);
    }

    runInAction(() => (this.hasLoadedDashboardData = true));
    runInAction(() => (this.isLoadingDashboardData = false));
  };

  loadUserCompanyCustomersCRCValid = async ({ auth, companies = [] }) => {
    let params = new URLSearchParams();
    let results;
    for (let company of companies) {
      params.append('companies', company);
    }

    try {
      let user_company_valid_crc = axios.get(
        `${api.url}/user/company/customers/crc/assets/valid/`,
        Object.assign(auth.requestConfig, { params })
      );
      results = await user_company_valid_crc;
      return results.data.customer_valid_crc_export;
    } catch (err) {
      this.loadDashboardDataFailure(err);
    }

    runInAction(() => (this.hasLoadedDashboardDataStatistics = true));
    runInAction(() => (this.isLoadingDashboardDataStatistics = false));
  };

  loadUserCompanyCustomersCRCResponses = async ({ auth, companies = [] }) => {
    let params = new URLSearchParams();
    for (let company of companies) {
      params.append('companies', company);
    }

    try {
      let user_company_responses_crc = axios.get(
        `${api.url}/user/company/customers/crc/assets/responses/`,
        Object.assign(auth.requestConfig, { params })
      );
      const results = await user_company_responses_crc;

      return results.data.customer_responses_export;
    } catch (err) {
      this.loadDashboardDataFailure(err);
    }

    runInAction(() => (this.hasLoadedDashboardDataStatistics = true));
    runInAction(() => (this.isLoadingDashboardDataStatistics = false));
  };

  loadDashboardDataFailure = action((err) => {
    this.isLoadingDashboardDataStatistics = false;
    this.hasLoadedDashboardDataStatistics = true;
  });
}

decorate(UserDashboardCustomersStore, {
  isLoadingDashboardDataStatistics: observable,
  hasLoadedDashboardDataStatistics: observable,
  isLoadingDashboardData: observable,
  hasLoadedDashboardData: observable,
  user_company_customers_count: observable,
  user_company_customers_results: observable,
  user_company_customers_with_valid_crc: observable,
  loadDashboardDataFailure: action,
});

export default UserDashboardCustomersStore;
