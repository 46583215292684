export function addHttpsOnUrl(url: string | null) {
  if (url === null || url.trim() === '') {
    return '';
  }
  const hasHttp = url.startsWith('http://') || url.startsWith('https://');
  const hasWww = url.includes('www.');
  let amendedUrl = url;

  if (!hasHttp) {
    amendedUrl = 'https://' + amendedUrl;
  }

  if (!hasWww) {
    const protocolEndIndex = amendedUrl.indexOf('//') + 2;
    amendedUrl =
      amendedUrl.slice(0, protocolEndIndex) +
      'www.' +
      amendedUrl.slice(protocolEndIndex);
  }

  return amendedUrl;
}
