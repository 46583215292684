import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  useRoutes,
  useLocation,
  useNavigate,
  Navigate,
  RouteObject,
} from 'react-router-dom';
import { configure } from 'mobx';
import useAuth from 'hooks/useAuth';

import Layout from 'components/App/Layout';
import NoMatch from './NoMatch';
import SignUp from './SignUp';
import VerifyEmail from './VerifyEmail';
import Login from './Login';

import Admin from './Admin';
import AdminCompany from './Admin/Company';
import AdminScreening from './Admin/Screening';
import AdminSearch from './Admin/Search';
import AdminMerge from './Admin/Merge';
import CPASurveys from './Admin/CPASurveys';
import CPASurvey from './Admin/CPASurvey';
import Company from './Company';
import CompaniesLayout from './Companies/CompaniesLayout';
import CompaniesListSearch from './Companies/Search';
import CompaniesListFavorites from './Companies/Favorites';

import UserDashboardCustomers from './Dashboard//MyCustomers';
import UserCompanyProfile from './User/CompanyProfile';
import UserPreferences from './User/Preferences';
import UserCompanySettings from './User/UserCompanySettings';
import UserCompanyAccessControl from './User/UserCompanySettings/UserCompanyVisibilityControls';
import UserCompanyMyUsers from './User/UserCompanySettings/UserCompanyMyUsers';
import UserCompanyInformation from './User/UserCompanySettings/UserCompanyInformation';
import UserCompanyData from './User/UserCompanySettings/UserCompanyData';
import WizardContent from './CrcWizard/Wizard/WizardContent';
import PendingEmailVerification from './PendingEmailVerification';
import Logout from './Logout';
import ForgetPassword from './ForgetPassword';
import ChangePassword from './ChangePassword';
import TermsConditions from './TermsConditions';

import Dashboard from './Dashboard/Component';

import UserDashboardMyCompany from './Dashboard/MyCompany';

import UserDashboardMySuppliers from './Dashboard/MySuppliers';
import UserDashboardSuppliersScreening from './Dashboard/MySuppliers/SuppliersScreening';
import UserDashboardSupplierAnalytics from './Dashboard/MySuppliers/SupplierAnalytics';
import UserDashboardSuppliersDemoEmissions from './Dashboard/MySuppliers/UserDashboardSuppliersDemoEmissions';
import UserDashboardSuppliersList from './Dashboard/MySuppliers/SuppliersList';

import { phCapture } from 'utils/posthog';
import DataServicesCDP from './Admin/Company/DataServiceCDP';
import DataServiceHtmlHarvester from './Admin/Company/DataServiceHtmlHarvester';
import DataServicePDF from './Admin/Company/DataServicePDF';
import DataServicePDFtoMARKDOWN from './Admin/Company/DataServicePDFtoMARKDOWN';
import DataServiceContentToUncheckedData from './Admin/Company/DataServiceContentToUncheckedData';
import DataServiceRun from './Admin/Company/DataServiceRun';

configure({ enforceActions: 'always' });

const Routes = () => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      document.URL.includes('https://test') &&
      !document.cookie.includes('tcc_test=true')
    ) {
      navigate('/access-denied', { replace: true });
    } else if (auth.isAuthenticated && !auth.verifiedEmail) {
      navigate('/pending-email-verification', { replace: true });
    } else if (auth.isAuthenticated && !auth.userDetails.has_completed_crc) {
      navigate('/crc-questions', { replace: true });
    } else if (
      auth.isAuthenticated &&
      !(auth.acceptedPrivacyPolicy && auth.acceptedTerms)
    ) {
      navigate('/terms-and-conditions', { replace: true });
    }
  }, [
    navigate,
    auth.isAuthenticated,
    auth.verifiedEmail,
    auth.userDetails.has_completed_crc,
    auth.acceptedPrivacyPolicy,
    auth.acceptedTerms,
  ]);

  // capture the pageview for all router changes
  phCapture('$pageview');

  const pathSplit = location.pathname.split('/');
  const legacyAppRoutes: RouteObject[] = [
    {
      path: 'company/user/profile',
      element: <Navigate to="/user/company/profile" replace={true} />,
    },
    {
      path: 'companies/search/:id',
      element: (
        <Navigate
          to={`/company/${pathSplit[pathSplit.length - 1]}`}
          replace={true}
        />
      ),
    },
    {
      path: 'cpa-surveys',
      element: <Navigate to="/admin/cpa-surveys" replace={true} />,
    },
    {
      path: 'cpa-surveys/:surveyId',
      element: (
        <Navigate
          to={`/admin/cpa-surveys/${pathSplit[pathSplit.length - 1]}`}
          replace={true}
        />
      ),
    },
    {
      path: 'dashboard/crc/*',
      element: <Navigate to="/dashboard/my-company/insights" replace={true} />,
    },
    {
      path: 'dashboard/cpa/status',
      element: <Navigate to="/dashboard/my-company/status" replace={true} />,
    },
    {
      path: 'dashboard/cpa/analytics',
      element: <Navigate to="/dashboard/my-company/analytics" replace={true} />,
    },
    {
      path: 'companies/favorites',
      element: <Navigate to="/companies/watchlist" replace={true} />,
    },
    {
      path: 'dashboard/my-company/insights',
      element: <Navigate to="/dashboard/my-company" replace={true} />,
    },
    {
      path: 'dashboard/my-company/analytics',
      element: <Navigate to="/dashboard/my-company" replace={true} />,
    },
    {
      path: 'dashboard/my-company/status',
      element: <Navigate to="/dashboard/my-company" replace={true} />,
    },
    {
      path: 'dashboard/my-company/scorecard',
      element: <Navigate to="/dashboard/my-company" replace={true} />,
    },
    {
      path: 'dashboard/home',
      element: <Navigate to="/dashboard/my-company" replace={true} />,
    },
  ];

  const adminRoutes: RouteObject[] = [
    {
      path: 'admin',
      element: <Admin />,
      children: [
        {
          path: 'company',
          element: <AdminCompany />,
        },
        {
          path: 'company/:companyId',
          element: <AdminCompany />,
        },
        {
          path: 'company/:companyId/services/cdp',
          element: <DataServicesCDP />,
        },
        {
          path: 'company/:companyId/services/html-harvester',
          element: <DataServiceHtmlHarvester />,
        },
        {
          path: 'company/:companyId/services/pdf-to-text',
          element: <DataServicePDF />,
        },
        {
          path: 'company/:companyId/services/pdf-to-markdown',
          element: <DataServicePDFtoMARKDOWN />,
        },
        {
          path: 'company/:companyId/services/content-to-unchecked-data',
          element: <DataServiceContentToUncheckedData />,
        },
        {
          path: 'job/:jobId',
          element: <DataServiceRun />,
        },
        {
          path: 'cpa-surveys',
          element: <CPASurveys />,
        },
        {
          path: 'cpa-surveys/:surveyId',
          element: <CPASurvey />,
        },
        {
          path: 'screening',
          element: <AdminScreening />,
        },
        {
          path: 'screening/:companyId',
          element: <AdminScreening />,
        },
        {
          path: 'screening/:companyId/:screeningListId',
          element: <AdminScreening />,
        },
        {
          path: 'search',
          element: <AdminSearch />,
        },
        {
          path: 'merge',
          element: <AdminMerge />,
        },
        {
          path: 'merge/:source',
          element: <AdminMerge />,
        },
        {
          path: 'merge/:source/:target',
          element: <AdminMerge />,
        },
      ],
    },
  ];

  const companyRoutes: RouteObject[] = [
    {
      path: 'companies',
      element: <CompaniesLayout />,
      children: [
        { index: true, element: <Navigate to="search" replace={true} /> },
        { path: 'search', element: <CompaniesListSearch /> },
        { path: 'watchlist', element: <CompaniesListFavorites /> },
      ],
    },
    { path: 'company/:id', element: <Company /> },
  ];

  const dashboardRoutes: RouteObject[] = [
    {
      path: 'dashboard',
      element: <Dashboard />,
      children: [
        { index: true, element: <Navigate to="my-company" replace={true} /> },
        {
          path: 'my-company',
          element: <UserDashboardMyCompany />,
          children: [
            {
              index: true,
              element: <Navigate to="analytics" replace={true} />,
            },
          ],
        },
        {
          path: 'my-suppliers',
          element: <UserDashboardMySuppliers />,
          children: [
            {
              index: true,
              element: <Navigate to="list" replace={true} />,
            },
            {
              path: 'screening',
              element: <UserDashboardSuppliersScreening />,
            },
            {
              path: 'analytics',
              element: <UserDashboardSupplierAnalytics />,
            },
            auth.userDetails.is_staff
              ? {
                  path: 'cpa-analytics',
                  element: <UserDashboardSuppliersDemoEmissions />,
                }
              : {},
            { path: 'list', element: <UserDashboardSuppliersList /> },
          ],
        },
        { path: 'my-customers', element: <UserDashboardCustomers /> },
      ],
    },
  ];

  const userRoutes: RouteObject[] = [
    {
      path: 'user',
      children: [
        { index: true, element: <Navigate to="preferences" replace={true} /> },
        { path: 'preferences', element: <UserPreferences /> },
        {
          path: 'company',
          children: [
            { index: true, element: <Navigate to="profile" replace={true} /> },
            { path: 'profile', element: <UserCompanyProfile /> },
            {
              path: 'settings',
              element: <UserCompanySettings />,
              children: [
                {
                  index: true,
                  element: <Navigate to="access-control" replace={true} />,
                },
                {
                  path: 'access-control',
                  element: <UserCompanyAccessControl />,
                },
                { path: 'my-users', element: <UserCompanyMyUsers /> },
                { path: 'edit', element: <UserCompanyInformation /> },
                { path: 'data', element: <UserCompanyData /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const appRoutes: RouteObject[] = [
    {
      path: '',
      element: auth.isAuthenticated ? (
        <Navigate to="/dashboard/home" replace={true} />
      ) : (
        <Navigate to="/login" replace={true} />
      ),
    },
    {
      path: 'logout',
      element: auth.isAuthenticated ? (
        <Logout />
      ) : (
        <Navigate to="/login" replace={true} />
      ),
    },
    {
      path: 'signup',
      element: auth.isAuthenticated ? (
        <Navigate to="/dashboard/home" replace={true} />
      ) : (
        <SignUp />
      ),
    },
    {
      path: 'signup/:buyerId',
      element: auth.isAuthenticated ? (
        <Navigate to="/dashboard/home" replace={true} />
      ) : (
        <SignUp />
      ),
    },
    {
      path: 'pending-email-verification',
      element: auth.isAuthenticated ? (
        <PendingEmailVerification />
      ) : (
        <Navigate to="/login" replace={true} />
      ),
    },
    {
      path: 'verify-email/:uid/:token',
      element: <VerifyEmail />,
    },
    {
      path: 'crc-questions',
      element: auth.isAuthenticated ? (
        <WizardContent />
      ) : (
        <Navigate to="/login" replace={true} />
      ),
    },
    {
      path: 'terms-and-conditions',
      element: <TermsConditions />,
    },
    ...legacyAppRoutes,
    ...(auth.hasAdminPermission ? adminRoutes : []),
    ...(auth.hasCompanyPermission ? companyRoutes : []),
    ...(auth.hasDashboardPermission ? dashboardRoutes : []),
    ...(auth.hasUserPermission ? userRoutes : []),
    {
      path: '*',
      element: auth.isAuthenticated ? (
        <NoMatch />
      ) : (
        <Navigate
          to={'/login'}
          state={{ from: location.pathname + location.search }}
          replace={true}
        />
      ),
    },
  ];

  return useRoutes([
    {
      path: '/login',
      element: auth.isAuthenticated ? (
        <Navigate
          to={location.state?.from || '/dashboard/home'}
          replace={true}
        />
      ) : (
        <Login />
      ),
    },
    {
      path: '/forget',
      element: <ForgetPassword />,
    },
    {
      path: '/password-reset/:uid/:token',
      element: <ChangePassword type={'set'} />,
    },
    {
      path: '/password-set/:uid/:token',
      element: <ChangePassword type={'set'} />,
    },
    {
      path: '/access-denied',
      element: <NoMatch />,
    },
    {
      path: '/*',
      element: <Layout />,
      children: appRoutes,
    },
  ]);
};

export default observer(Routes);
