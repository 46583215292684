export const downloadFile = async (fileSrc: string,fileName:string,fileType:string) => {
    const file = await fetch(fileSrc);
    const fileBlog = await file.blob();
    const fileURL = URL.createObjectURL(fileBlog);

    const link = document.createElement('a');
    link.href = fileURL;
    link.download = `${fileName}.${fileType}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }