import React from 'react';

import Grid from 'components/common/Grid';

import Address from './Address/Address';
import Website from './Website/Website';
import Email from './Email/Email';
import * as schema from 'types/backendSchema';

export type Props = {
  companyData: schema.definitions['PublicCompany'];
};

function CompanyDetails({ companyData }: Props) {
  return (
    <Grid item container justifyContent="space-between" mt={'22px'}>
      <Address
        address={companyData.address}
        address2={companyData.address2}
        country={companyData.country}
        city={companyData.city}
        zip_code={companyData.zip_code}
      />
      <Website website={companyData.website} />
      <Email email={companyData.email} />
    </Grid>
  );
}

export default CompanyDetails;
