import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import Alert from '@mui/material/Alert';

import Button from 'components/common/Button';
import TccInputTextField from 'components/common/TccInputTextField';
import Form from 'components/common/Form';
import Stack from 'components/common/Stack';
import SectorSelect from 'components/common/SectorSelect';
import CountrySelect from 'components/common/CountrySelect';
import { FormControlLabel } from '@mui/material';
import TccCheckbox from 'components/common/TccCheckbox';
// import TagsCodesCommon from './TagsCodesCommon';
// import EnumCategoriesCommon from './EnumCategoriesCommon';

import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import CompanyAvatar from 'components/App/Routes/Company/CompanyDetailsPage/CompanyHeader/CompanyAvatar/CompanyAvatar';

import { CloseIcon, UploadIcon } from 'components/icons/mui_icons';

import colors from 'constants/colors';
import { getMediaUrlSrc } from 'utils/browser';
import Typography from '@mui/material/Typography';

import FormStore from 'state/FormStore/FormStore';

import useStore from 'components/App/Routes/Admin/Search/store/useStore';

const CompanyEditForm = ({
  form,
  diffForm,
  onClose,
  onSuccess,
  disabled,
}: {
  form: FormStore;
  diffForm?: FormStore;
  onClose?: any;
  disabled?: boolean;
  onSuccess?: (data: any) => void;
}) => {
  const { t } = useTranslation('common');
  const auth = useAuth();

  const { setInvalid } = useStore();
  const [moreFields, setMoreFields] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);

  const { mutate, isLoading, isError, reset, error } = useMutation({
    onSuccess: (res) => {
      if (res && res.data) {
        form.resetValues(res.data);
      }
      setInvalid(true);
      if (onSuccess) {
        onSuccess(res ? res.data : {});
      }
    },
    onError: (err) => {
      console.log(err);
    },
    mutationFn: async () => {
      if (!form.values.has('id')) return;
      if (form.values.get('id') === 'NEW') {
        const data = form.deserialize();
        const formData = new FormData();
        for (let key of Object.keys(data)) {
          if (
            key !== 'certifications' &&
            key !== 'sdgs' &&
            key !== 'tcc_affiliations' &&
            key !== 'tcc_impact_categories' &&
            key !== 'id' &&
            data[key] !== null
          ) {
            formData.set(key, data[key]);
          }
        }
        const res = await axios.post(
          `${api.url}/admin/companies/`,
          formData,
          auth.requestConfig
        );

        return res;
      } else {
        const data = form.deserializeDirty();
        const formData = new FormData();
        for (let key of Object.keys(data)) {
          if (
            key !== 'certifications' &&
            key !== 'sdgs' &&
            key !== 'tcc_affiliations' &&
            key !== 'tcc_impact_categories' &&
            data[key] !== null
          ) {
            formData.set(key, data[key]);
          }
        }
        const res = await axios.patch(
          `${api.url}/admin/companies/${form.values.get('id')}/`,
          formData,
          auth.requestConfig
        );

        return res;
      }
    },
  });
  const url = window.location.toString();

  // eslint-disable-next-line
  const isFieldError = (fieldName = '') =>
    form.errors.has(fieldName) ||
    (diffForm &&
      diffForm.values.has(fieldName) &&
      diffForm.values.get(fieldName) !== form.values.get(fieldName));

  return (
    <Form onSubmit={() => mutate()}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ mr: 1 }}>
                Slug
              </Typography>
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  url.includes('http://localhost') ||
                  url.includes('https://dev') ||
                  url.includes('https://test')
                    ? `https://test-api.theclimatechoice.tech/admin/companies/company/${form.values.get(
                        'id'
                      )}`
                    : `https://api.theclimatechoice.com/hd62k2a/companies/company/${form.values.get(
                        'id'
                      )}`
                }
              >
                {form.values.get('slug')}
              </a>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" sx={{ mr: 1 }}>
                {t('Id:')}
              </Typography>
              {form.values.get('id')}
            </Grid>
            <Button variant="text" onClick={() => setMoreInfo(!moreInfo)}>
              {moreInfo ? t('Less') : t('More')}
            </Button>
            {moreInfo ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Hubspot Id:')}
                  </Typography>
                  {form.values.get('hubspot_id')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Last Hubspot Sync:')}
                  </Typography>
                  {form.values.get('last_hubspot_sync')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Created:')}
                  </Typography>
                  {form.values.get('created')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Modified:')}
                  </Typography>
                  {form.values.get('modified')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Initial Source:')}
                  </Typography>
                  {form.values.get('initial_source')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Profile Search Ranking:')}
                  </Typography>
                  {form.values.get('profile_search_ranking')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Last Screened:')}
                  </Typography>
                  {form.values.get('last_screened')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Customer Valid Crcs Export:')}
                  </Typography>
                  {form.values.get('customer_valid_crcs_export')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Latest Customer Response Export:')}
                  </Typography>
                  {form.values.get('latest_customer_response_export')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Supplier Valid Crcs Export:')}
                  </Typography>
                  {form.values.get('supplier_valid_crcs_export')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Banner:')}
                  </Typography>
                  {form.values.get('banner')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Employee Range:')}
                  </Typography>
                  {form.values.get('employee_range')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {'Turnover'}
                  </Typography>
                  {form.values.get('turnover')}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" sx={{ mr: 1 }}>
                    {t('Acquisition Type:')}
                  </Typography>
                  {form.values.get('acquisition_type')}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={2} alignItems={{ xs: 'left' }} mb={'10px'}>
            <Stack spacing={2} alignItems="left">
              <Typography
                variant={'body1'}
                color={
                  form.errors.has('logo')
                    ? 'error.main'
                    : 'neutral.contrastText'
                }
              >
                {t('Company Logo')}
              </Typography>
              <CompanyAvatar
                hideChip
                companyName={form.values.get('name') || ''}
                companySlug={''}
                userCompanySlug={''}
                logo={getMediaUrlSrc(form.values.get('logo'))}
                isAdmin={false}
                published={form.values.get('published')}
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <label htmlFor="logo">
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  id="logo"
                  onChange={(e: any) => {
                    form.setValue('logo', e.target.files[0]);
                    const maxSize = 3140000; // 3MiB
                    let size = e.target.files[0]?.size || 0;
                    if (size > maxSize) {
                      form.setFieldError('logo', true);
                    } else {
                      form.removeFieldError('logo');
                    }
                  }}
                  style={{
                    color: colors.neutral200,
                  }}
                />
                <Button
                  variant="outlined"
                  color="inherit"
                  component="span"
                  disabled={isLoading}
                  startIcon={<UploadIcon />}
                >
                  {t('Choose file')}
                </Button>
              </label>
              <Button
                variant="outlined"
                color="inherit"
                component="span"
                disabled={isLoading || !form.values.get('logo')}
                startIcon={<CloseIcon />}
                onClick={() => form.setValue('logo', '')}
              >
                {t('Remove')}
              </Button>
            </Stack>
            {form.errors.has('logo') ? (
              <Alert severity="error">
                {t('Maximum file size is {{size}}', { size: '3MB' })}
              </Alert>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <TccCheckbox
                onChange={(e: any) => {
                  form.setValue('verified', e.target.checked);
                }}
                sx={{
                  paddingTop: '0px',
                  '.MuiFormControlLabel-asterisk': {
                    display: 'none',
                  },
                }}
                checked={form.values.get('verified') || false}
              />
            }
            sx={{ alignItems: 'flex-start' }}
            label={t('Is verified')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <TccCheckbox
                onChange={(e: any) => {
                  form.setValue('is_crc_visible', e.target.checked);
                }}
                sx={{
                  paddingTop: '0px',
                  '.MuiFormControlLabel-asterisk': {
                    display: 'none',
                  },
                }}
                checked={form.values.get('is_crc_visible') || false}
              />
            }
            sx={{ alignItems: 'flex-start' }}
            label={t('Is CPA visible')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <TccCheckbox
                onChange={(e: any) => {
                  form.setValue('published', e.target.checked);
                }}
                sx={{
                  paddingTop: '0px',
                  '.MuiFormControlLabel-asterisk': {
                    display: 'none',
                  },
                }}
                checked={form.values.get('published') || false}
              />
            }
            sx={{ alignItems: 'flex-start' }}
            label={t('Published')}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Company Name`)}
            isDirty={form.dirtyValues.has('name')}
            error={form.errors.has('name')}
            required
            onChange={(e: any) => {
              form.setValue('name', e.target.value);
            }}
            value={form.values.get('name') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Address Line 1`)}
            isDirty={form.dirtyValues.has('address')}
            error={form.errors.has('address')}
            onChange={(e: any) => {
              form.setValue('address', e.target.value);
            }}
            value={form.values.get('address') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Address Line 2`)}
            isDirty={form.dirtyValues.has('address2')}
            error={form.errors.has('address2')}
            onChange={(e: any) => {
              form.setValue('address2', e.target.value);
            }}
            value={form.values.get('address2') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Zip Code`)}
            isDirty={form.dirtyValues.has('zip_code')}
            error={form.errors.has('zip_code')}
            onChange={(e: any) => {
              form.setValue('zip_code', e.target.value);
            }}
            value={form.values.get('zip_code') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`City`)}
            isDirty={form.dirtyValues.has('city')}
            error={form.errors.has('city')}
            onChange={(e: any) => {
              form.setValue('city', e.target.value);
            }}
            value={form.values.get('city') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Region`)}
            isDirty={form.dirtyValues.has('region')}
            error={form.errors.has('region')}
            onChange={(e: any) => {
              form.setValue('region', e.target.value);
            }}
            value={form.values.get('region') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <CountrySelect
            title={t('Country')}
            onChange={(country) => {
              form.setValue('country', country);
            }}
            value={form.values.get('country')}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Email`)}
            isDirty={form.dirtyValues.has('email')}
            error={form.errors.has('email')}
            helpText={form.errors.get('email')}
            onChange={(e: any) => {
              form.setValue('email', e.target.value);
              form.removeFieldError('email');
            }}
            value={form.values.get('email') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Website`)}
            isDirty={form.dirtyValues.has('website')}
            error={form.errors.has('website')}
            helpText={form.errors.get('website')}
            onChange={(e: any) => {
              form.setValue('website', e.target.value);
              form.removeFieldError('website');
            }}
            value={form.values.get('website') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Phone`)}
            isDirty={form.dirtyValues.has('phone')}
            error={form.errors.has('phone')}
            rows={6}
            onChange={(e: any) => {
              form.setValue('phone', e.target.value);
            }}
            value={form.values.get('phone') || ''}
          />
        </Grid>

        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Currency`)}
            isDirty={form.dirtyValues.has('currency')}
            error={form.errors.has('currency')}
            rows={1}
            onChange={(e: any) => {
              form.setValue('currency', e.target.value);
            }}
            value={form.values.get('currency') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <TccCheckbox
                onChange={(e: any) => {
                  form.setValue('has_parent_company', e.target.checked);
                }}
                sx={{
                  paddingTop: '0px',
                  '.MuiFormControlLabel-asterisk': {
                    display: 'none',
                  },
                }}
                checked={form.values.get('has_parent_company') || false}
              />
            }
            sx={{ alignItems: 'flex-start' }}
            label={t('Has Parent Company')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <TccCheckbox
                onChange={(e: any) => {
                  form.setValue('has_subsidiaries', e.target.checked);
                }}
                sx={{
                  paddingTop: '0px',
                  '.MuiFormControlLabel-asterisk': {
                    display: 'none',
                  },
                }}
                checked={form.values.get('has_subsidiaries') || false}
              />
            }
            sx={{ alignItems: 'flex-start' }}
            label={t('Has Subsidiaries')}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Parent Company Name`)}
            isDirty={form.dirtyValues.has('parent_company_name')}
            error={form.errors.has('parent_company_name')}
            rows={6}
            onChange={(e: any) => {
              form.setValue('parent_company_name', e.target.value);
            }}
            value={form.values.get('parent_company_name') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Company LEI code`)}
            subTitle={t('Legal Entity Identifier (LEI) code for your company')}
            isDirty={form.dirtyValues.has('lei_code')}
            error={form.errors.has('lei_code')}
            helpText={form.errors.get('lei_code')}
            onChange={(e: any) => {
              form.setValue('lei_code', e.target.value);
            }}
            value={form.values.get('lei_code') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`ISIN`)}
            subTitle={t('ISIN code')}
            isDirty={form.dirtyValues.has('isin')}
            error={form.errors.has('isin')}
            helpText={form.errors.get('isin')}
            onChange={(e: any) => {
              form.setValue('isin', e.target.value);
            }}
            value={form.values.get('isin') || ''}
          />
        </Grid>
        <Grid item xs={12} mb={'10px'}>
          <SectorSelect
            title={t('Sector')}
            onChange={(sector) => {
              form.setValue('sector', sector);
            }}
            value={form.values.get('sector')}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Short Description`)}
            isDirty={form.dirtyValues.has('short_description')}
            error={form.errors.has('short_description')}
            multiline
            rows={3}
            onChange={(e: any) => {
              form.setValue('short_description', e.target.value);
            }}
            value={form.values.get('short_description') || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TccInputTextField
            title={t(`Description`)}
            isDirty={form.dirtyValues.has('description')}
            error={form.errors.has('description')}
            multiline
            rows={6}
            onChange={(e: any) => {
              form.setValue('description', e.target.value);
            }}
            value={form.values.get('description') || ''}
          />
        </Grid>
        <Button variant="text" onClick={() => setMoreFields(!moreFields)}>
          {moreFields ? t('Less') : t('More')}
        </Button>
        {moreFields ? (
          <>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Facebook Link`)}
                isDirty={form.dirtyValues.has('facebook_handle')}
                error={form.errors.has('facebook_handle')}
                onChange={(e: any) => {
                  form.setValue('facebook_handle', e.target.value);
                }}
                value={form.values.get('facebook_handle') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Instagram Link`)}
                isDirty={form.dirtyValues.has('instagram_handle')}
                error={form.errors.has('instagram_handle')}
                onChange={(e: any) => {
                  form.setValue('instagram_handle', e.target.value);
                }}
                value={form.values.get('instagram_handle') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Linked In Link`)}
                isDirty={form.dirtyValues.has('linkedin_handle')}
                error={form.errors.has('linkedin_handle')}
                onChange={(e: any) => {
                  form.setValue('linkedin_handle', e.target.value);
                }}
                value={form.values.get('linkedin_handle') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={t(`Twitter Link`)}
                isDirty={form.dirtyValues.has('twitter_handle')}
                error={form.errors.has('twitter_handle')}
                onChange={(e: any) => {
                  form.setValue('twitter_handle', e.target.value);
                }}
                value={form.values.get('twitter_handle') || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`Tcc impact categories`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('tcc_impact_categories')}
                error={form.errors.has('tcc_impact_categories')}
                onChange={(e: any) => {
                  form.setValue('tcc_impact_categories', e.target.value);
                }}
                value={
                  JSON.stringify(form.values.get('tcc_impact_categories')) || ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`Tcc affiliations`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('tcc_affiliations')}
                error={form.errors.has('tcc_affiliations')}
                onChange={(e: any) => {
                  form.setValue('tcc_affiliations', e.target.value);
                }}
                value={
                  JSON.stringify(form.values.get('tcc_affiliations')) || ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`SDGs`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('sdgs')}
                error={form.errors.has('sdgs')}
                onChange={(e: any) => {
                  form.setValue('sdgs', e.target.value);
                }}
                value={JSON.stringify(form.values.get('sdgs')) || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`Certifications`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('certifications')}
                error={form.errors.has('certifications')}
                onChange={(e: any) => {
                  form.setValue('certifications', e.target.value);
                }}
                value={JSON.stringify(form.values.get('certifications')) || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`Product Tags`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('tcc_product_tags')}
                error={form.errors.has('tcc_product_tags')}
                onChange={(e: any) => {
                  form.setValue('tcc_product_tags', e.target.value);
                }}
                value={
                  JSON.stringify(form.values.get('tcc_product_tags')) || ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`Nace codes`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('tcc_nace_codes')}
                error={form.errors.has('tcc_nace_codes')}
                onChange={(e: any) => {
                  form.setValue('tcc_nace_codes', e.target.value);
                }}
                value={JSON.stringify(form.values.get('tcc_nace_codes')) || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`Impact Tags`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('tcc_impact_tags')}
                error={form.errors.has('tcc_impact_tags')}
                onChange={(e: any) => {
                  form.setValue('tcc_impact_tags', e.target.value);
                }}
                value={JSON.stringify(form.values.get('tcc_impact_tags')) || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TccInputTextField
                title={`EU Taxonomy Codes`}
                disabled
                multiline
                rows={3}
                isDirty={form.dirtyValues.has('tcc_eu_taxonomy_codes')}
                error={form.errors.has('tcc_eu_taxonomy_codes')}
                onChange={(e: any) => {
                  form.setValue('tcc_eu_taxonomy_codes', e.target.value);
                }}
                value={
                  JSON.stringify(form.values.get('tcc_eu_taxonomy_codes')) || ''
                }
              />
            </Grid>
          </>
        ) : null}
        {isError ? (
          <Grid item xs={12}>
            <Alert severity="error">
              {(error as any)?.response?.data
                ? JSON.stringify((error as any)?.response?.data)
                : t('There was a problem')}
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Stack spacing={2} direction={'row'}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabled || !form.hasDirtyValues || isLoading}
            >
              {t('Save')}
            </Button>
            <Button
              onClick={() => {
                reset();
                if (onClose) {
                  onClose();
                }
              }}
            >
              {t('Cancel')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default observer(CompanyEditForm);
