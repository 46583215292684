import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useAdminCompany(companyId?: string) {
  const auth = useAuth();
  return useQuery({
    enabled: !!companyId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    // refetchInterval: 5000,
    // cacheTime: 0,
    staleTime: 5000,
    queryKey: ['admin-company', { companyId }],
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/admin/companies/${companyId}/`,
        auth.requestConfig
      );
      return res.data as { [key: string]: string };
    },
  });
}
