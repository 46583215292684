import React from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@mui/styles';

import useAuth from 'hooks/useAuth';
import useCompanies from 'hooks/useCompanies';
import { useSearchParams } from 'react-router-dom';
import useMultiSelectFilterNavigation from 'hooks/useMultiSelectFilterNavigation';

import StyledTagButton from 'components/common/StyledTagButton';
import { impactCategoriesIcons } from 'components/icons/impact_categories/index';
import colors from 'constants/colors';

const useStyles = makeStyles((theme: any) => ({
  formControl: {
    minWidth: 180,
    width: '100%',
  },
  container: {
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
  childContainer: {
    padding: theme.spacing(1),
  },
  iconText: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

const ImpactCategoryFilter = () => {
  const classes = useStyles();
  const auth = useAuth();
  let [searchParams] = useSearchParams();
  const multiSelectFilter = useMultiSelectFilterNavigation();

  const {
    loadImpactCategories,
    impactCategories,
    isLoadingImpactCategories,
    hasLoadedImpactCategories,
  } = useCompanies();
  const keys = Object.keys(impactCategories);

  React.useEffect(() => {
    if (!hasLoadedImpactCategories && !isLoadingImpactCategories) {
      loadImpactCategories({ auth });
    }
  }, [
    auth,
    loadImpactCategories,
    hasLoadedImpactCategories,
    isLoadingImpactCategories,
  ]);

  return (
    !isLoadingImpactCategories && (
      <div className={classes.container}>
        {Object.entries(impactCategories).map((filterObjArray, i) => {
          let readableText = `${filterObjArray[1]}`;
          //@ts-ignore
          const Component = impactCategoriesIcons[filterObjArray[0]];
          return (
            <div key={i} style={{ overflow: 'hidden' }}>
              <StyledTagButton
                key={i}
                height={'40px'}
                width={'204px'}
                title={readableText}
                justifyContent="flex-start"
                value={keys[i]}
                sx={{ mt: '2px' }}
                buttonColor={
                  searchParams.get('tcc_impact_categories')?.includes(keys[i])
                    ? 'primary.main'
                    : 'white'
                }
                buttonColorHover="primary.main"
                fontColor={
                  searchParams.get('tcc_impact_categories')?.includes(keys[i])
                    ? 'white'
                    : colors.neutral700
                }
                fontColorOnHover={'white'}
                onClick={(e: any) => {
                  multiSelectFilter('tcc_impact_categories', e.target.value);
                }}
              >
                {
                  <Component
                    sx={{
                      mr: '16px',
                      width: '16px',
                      height: '16px',
                      pointerEvents: 'none',
                      color: searchParams
                        .get('tcc_impact_categories')
                        ?.includes(keys[i])
                        ? 'white'
                        : 'inherit',
                    }}
                  />
                }
              </StyledTagButton>
            </div>
          );
        })}
      </div>
    )
  );
};

export default observer(ImpactCategoryFilter);
