import React from 'react';
import { observer } from 'mobx-react-lite';
import { useSearchParams, useLocation } from 'react-router-dom';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { scrollToTop } from 'utils/browser';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from '@mui/material/Pagination';

import useCompanies from 'hooks/useCompanies';

import CompaniesListDataItem from './CompaniesListDataItem';
import Grid from 'components/common/Grid';
import Typography from 'components/common/Typography';

import CompaniesSortingMenu from './CompaniesSortingMenu';

import colors from 'constants/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  pagination: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  loading: {
    position: 'absolute',
    width: '100%',
    top: '-4px',
  },
}));

const CompaniesListData = ({ rows = [] }: { rows: any[] }) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  let [searchParams] = useSearchParams();
  const navigateSearch = useNavigateSearch();
  const location = useLocation();
  const { count, isLoadingCompanies, hasLoadedCompanies } = useCompanies();

  const onPageChange = React.useCallback(
    (e: any, page: number) => {
      navigateSearch({
        pathname: location.pathname,
        params: {
          page: page,
        },
        replace: false,
      });
      scrollToTop();
    },
    [location.pathname, navigateSearch]
  );

  return (
    <div className={classes.container}>
      <div style={{ paddingBottom: '16px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <Typography
              variant="subtitle1"
              color={colors.neutral600}
              sx={{ padding: (theme: any) => theme.spacing(3, 0, 2, 0) }}
            >
              {' '}
              {t('{{count}} result found', { count })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography variant="subtitle1" color={colors.neutral500}>
              {t('Sort by')}
            </Typography>
            <CompaniesSortingMenu />
          </Grid>
        </Grid>
      </div>
      <div className={classes.container}>
        {isLoadingCompanies && (
          <LinearProgress className={classes.loading} color="primary" />
        )}
        {rows.map((row, index) => (
          <CompaniesListDataItem key={index} row={row} />
        ))}
      </div>
      {!isLoadingCompanies && hasLoadedCompanies && (
        <div className={classes.pagination}>
          <Pagination
            onChange={onPageChange}
            color="primary"
            count={
              count % 10 ? Math.floor(count / 10) + 1 : Math.floor(count / 10)
            } // Before - when the count was between 10-20, all companies were going in one page page
            siblingCount={2}
            boundaryCount={2}
            shape="rounded"
            defaultPage={1}
            page={parseInt(searchParams.get('page') || '1')}
          />
        </div>
      )}
    </div>
  );
};

export default observer(CompaniesListData);
