import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import useCPASurvey from '../store/useCPASurvey';
import Grid from 'components/common/Grid';
import Input from 'components/common/Input';
import Checkbox from 'components/common/Checkbox';
import Switch from 'components/common/Switch';

const useStyles = makeStyles((theme) => ({
  firstControl: {
    display: 'flex',
    height: theme.spacing(2),
  },
  control: {
    paddingBottom: theme.spacing(1),
    maxWidth: theme.breakpoints.values.sm,
  },
  controlLabel: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
  },
}));

const CPASurveyQuestionEdit = ({ id, answers, index, url, values }) => {
  const { edits, saveEdit, saveNote, notes } = useCPASurvey();
  const { t } = useTranslation('common');
  const classes = useStyles();
  const note = notes.get(id);
  const [useCheckboxes, setUseCheckboxes] = useState(true);

  return (
    <Grid container spacing={2}>
      {index !== 0 && (
        <Grid item xs={12} sx={useCheckboxes ? { opacity: 0.5 } : {}}>
          <Switch
            sx={{ marginLeft: '8px' }}
            color="warning"
            size="small"
            label={'Override checkboxes?'}
            onChange={() => setUseCheckboxes(!useCheckboxes)}
            checked={!useCheckboxes}
          />
        </Grid>
      )}
      {url ? (
        <Grid item xs={12}>
          <a href={url}>{values.file_name}</a>
          <div className={classes.control}>file upload (work in progress)</div>
        </Grid>
      ) : (
        <Grid item xs={12}>
          {answers.map((value, i) => {
            return index === 0 ? (
              <div className={classes.control} key={`${id}_${i}`}>
                <div className={classes.firstControl} />
                <Input
                  fullWidth
                  label={value}
                  value={
                    edits.has(`${id}_${i}`)
                      ? edits.get(`${id}_${i}`)
                      : values[i] || ''
                  }
                  onChange={(e) => {
                    saveEdit(`${id}_${i}`, e.target.value);
                  }}
                />
              </div>
            ) : answers.length === 1 || !useCheckboxes ? (
              <div className={classes.control} key={`${id}_${i}`}>
                <div className={classes.controlLabel}>{value}</div>
                <Input
                  fullWidth
                  multiline
                  rows={5}
                  value={
                    edits.has(`${id}_${i}`)
                      ? edits.get(`${id}_${i}`)
                      : values[i] || ''
                  }
                  onChange={(e) => saveEdit(`${id}_${i}`, e.target.value)}
                />
              </div>
            ) : (
              <div key={`${id}_${i}`}>
                <Checkbox
                  checked={
                    edits.has(`${id}_${i}`)
                      ? !!edits.get(`${id}_${i}`)
                      : !!values[i] || false
                  }
                  onChange={(e) =>
                    saveEdit(`${id}_${i}`, e.target.checked ? value : '')
                  }
                  color="primary"
                  label={value}
                />
              </div>
            );
          })}
        </Grid>
      )}
      <Grid item xs={12}>
        <Input
          fullWidth
          multiline
          rows="5"
          label={t('Review Note')}
          name="note"
          value={note}
          onChange={(e) => saveNote(id, e.target.value)}
        />
      </Grid>
    </Grid>
  );
};
export default observer(CPASurveyQuestionEdit);
