export const sortArrayOfStringsAlphabetically = (
  strings: string[],
  reverse: boolean = false
) => {
  const newArrayStrings = strings.concat([]);
  return reverse
    ? newArrayStrings.sort((a, b) => b.localeCompare(a))
    : newArrayStrings.sort();
};

export const getClimateScoreAsPercent = (climateScore: number) => {
  return Math.round((climateScore / 19) * 100);
};

export const getClimateScoreCategoryScores = (scorecard?: {
  overall: number;
  categories: { score: number }[];
}) => {
  if (!scorecard) {
    return {
      emissionsCalculation: undefined,
      strategyAndControl: undefined,
      targets: undefined,
      decarbonizationMeasures: undefined,
      emissionsPerformance: undefined,
    };
  }
  const emissionsCalculation = Math.floor(
    scorecard?.categories[1]?.score
      ? (scorecard?.categories[1]?.score / 5) * 100
      : 0
  );
  const strategyAndControl = Math.floor(
    scorecard?.categories[3]?.score
      ? (scorecard?.categories[3]?.score / 2) * 100
      : 0
  );
  const targets = Math.floor(
    scorecard?.categories[4]?.score
      ? (scorecard?.categories[4]?.score / 5) * 100
      : 0
  );
  const decarbonizationMeasures = Math.floor(
    scorecard?.categories[0]?.score
      ? (scorecard?.categories[0]?.score / 4) * 100
      : 0
  );
  const emissionsPerformance = Math.floor(
    scorecard?.categories[2]?.score
      ? (scorecard.categories[2].score / 3) * 100
      : 0
  );
  return {
    emissionsCalculation,
    strategyAndControl,
    targets,
    decarbonizationMeasures,
    emissionsPerformance,
  };
};
