import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { useSearchParams } from 'react-router-dom';

import UserDashboardCustomersStore from './store/UserDashboardCustomersStore';
import useUserCompany from 'hooks/useUserCompany';

import Container from 'components/common/Container';
import Box from 'components/common/Box';
import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack/Stack';
import Typography from 'components/common/Typography';
import AppLoading from 'components/common/AppLoading';

import UserDashboardCustomersTable from './UserDashboardCustomersTable';
import UserDashboardCustomersHeader from './UserDashboardCustomersHeader/UserDashboardCustomersHeader';
import UserDashboardCustomersSearch from './UserDashboardCustomersSearch';
import UserDashboardCustomersAddCustomers from './UserDashboardCustomersAddCustomers';
import UserDashboardCustomersES from './UserDashboardCustomersES';

function UserDashboardCustomers() {
  const { t } = useTranslation('common');
  const auth = useAuth();
  let [searchParams] = useSearchParams();
  const {
    loadDashboardData,
    hasLoadedDashboardData,
    user_company_customers_results,
    user_company_customers_count_on_search,
  } = UserDashboardCustomersStore();
  const { userCompany } = useUserCompany();

  let page = searchParams.get('page') || '1';
  let search = searchParams.get('search') || '';
  const rowsPerPage = 100;

  const [pageState, setPageState] = useState('');
  const [searchState, setSearchState] = useState('');

  useEffect(() => {
    if (page !== pageState || searchState !== search) {
      setPageState(page);
      setSearchState(search);
      loadDashboardData({ auth, page, search, rowsPerPage });
    }
  }, [
    auth,
    hasLoadedDashboardData,
    loadDashboardData,
    page,
    pageState,
    search,
    searchState,
  ]);

  return !hasLoadedDashboardData ? (
    <Grid item sx={{ width: '100%' }}>
      <AppLoading />
    </Grid>
  ) : (
    <Container pageContainer maxWidth="lg" sx={{ pt: 3 }}>
      {userCompany.customers_count === 0 && hasLoadedDashboardData ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Typography variant="h4" color="onSureface.100">
            {t('My Customers')}
          </Typography>
          <UserDashboardCustomersES
            id={(auth.userDetails as any).id}
            companyName={(auth.userDetails as any).company}
            userDetails={auth.userDetails}
            rowDirection={true}
          />
        </Box>
      ) : (
        <Stack
          spacing={{ xs: 2, sm: 3 }}
          sx={{
            width: { xs: 'calc(100% - 65px)', lg: '100%' },
          }}
        >
          <UserDashboardCustomersHeader
            totalCustomersNumber={userCompany.customers_count}
          />
          <Box
            display="flex"
            flexDirection={{ xs: 'column-reverse', sm: 'row' }}
          >
            <Box flex={'1 1 auto'} pr={1} pt={{ xs: 2, sm: 0 }}>
              <UserDashboardCustomersSearch />
            </Box>
            <Box flex="0 0 auto" textAlign={'right'}>
              <UserDashboardCustomersAddCustomers
                id={(auth.userDetails as any).id}
                companyName={(auth.userDetails as any).company}
                userDetails={auth.userDetails}
              />
            </Box>
          </Box>
          <div
            style={{
              overflowX: 'auto',
            }}
          >
            <UserDashboardCustomersTable
              customers={user_company_customers_results} //user_company_customers_results
              customersCount={user_company_customers_count_on_search} //user_company_customers_count_on_search
              rowsPerPage={rowsPerPage}
            />
          </div>
        </Stack>
      )}
    </Container>
  );
}

export default observer(UserDashboardCustomers);
