import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Stack from 'components/common/Stack';
import Box from 'components/common/Box';
import Typography from '@mui/material/Typography';

import useUserCompany from 'hooks/useUserCompany';
import useAuth from 'hooks/useAuth';
import useCompany from 'hooks/useCompany';
import Switch from 'components/common/Switch';

const UserCompanyVisibilityControls = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { patchUserCompany, userCompany } = useUserCompany();
  const { removeCompany } = useCompany();

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('Search Visibility')}</Typography>
      <Box>
        <Typography
          variant={'caption2'}
          color={'neutral.contrastText'}
          mb={'10px'}
        >
          {t(
            'The following setting give the ability to control whether your company profile is visible in search results or not.'
          )}
        </Typography>
      </Box>
      <Switch
        label={t('Visible in Search')}
        checked={userCompany.published}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          patchUserCompany({
            auth,
            data: { published: e.target.checked },
          });
          removeCompany();
        }}
      />
    </Stack>
  );
};

export default observer(UserCompanyVisibilityControls);
