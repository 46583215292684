import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';
import SourceChannelChip from '../../SourceChannelChip';

import i18n from 'i18n';

export default function EmissionsDataTable({ data }: { data: any }) {
  const { t } = useTranslation('common');

  const rows = data.map((datapoints: any) => {
    return { id: Math.random(), ...datapoints };
  });

  const columns: GridColDef[] = [
    {
      field: 'reporting_year',
      headerName: t('Year'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('Type'),
      sortable: false,
      flex: 3,
    },
    {
      field: 'intensity_amount',
      headerName: t('Intensity'),
      sortable: false,
      flex: 1,
      renderCell: (params) =>
        Number(params.row.intensity_amount)
          ? Number(params.row.intensity_amount).toLocaleString(i18n.language, {
              minimumFractionDigits: 2,
            })
          : params.row.intensity_amount,
    },
    {
      field: 'source_channel',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];

  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        hideFooter
      />
    </div>
  );
}
