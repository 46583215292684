import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useNavigateSearch from 'hooks/useNavigateSearchParams';
import { useNavigate } from 'react-router-dom';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from 'components/icons/Check';

import Typography from 'components/common/Typography';
import Button from 'components/common/Button';
import Grid from 'components/common/Grid';
import Input from 'components/common/Input';
import DownloadFileButton from './DownloadFileButton';

import useAuth from 'hooks/useAuth';
import useCPASurvey from '../store/useCPASurvey';
import useSnackbarMessage from 'hooks/useSnackbarMessage';

const SurveyInfoFooter = ({ identifier }) => {
  const { t } = useTranslation('common');
  const {
    hasEdits,
    isLoadingSurvey,
    isPatchingSurveyData,
    final,
    form,
    patchSurvey,
    hasReviewUpdates,
  } = useCPASurvey();
  const auth = useAuth();
  const navigateSearch = useNavigateSearch();
  const navigate = useNavigate();
  const snackbarMessage = useSnackbarMessage();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Input
          fullWidth
          multiline
          rows="5"
          label={!final ? t('Review Note') : t('Final Note')}
          name="note"
          value={form.values.get('note')}
          error={form.errors.has('note')}
          helperText={form.errors.get('note')}
          onChange={(e) => form.setValue('note', e.target.value)}
          disabled={final}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3}>
            <Button
              fullWidth
              variant="contained"
              color="neutral"
              onClick={() =>
                navigateSearch({
                  pathname: '/cpa-surveys',
                  params: {
                    page: 1,
                  },
                  replace: true,
                })
              }
            >
              {t('Cancel')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={() =>
                patchSurvey({
                  auth,
                  navigateSearch,
                  goBack: () => navigate(-1),
                  cpasurveyId: identifier,
                  snackbarMessage,
                  status: 'FINALIZED',
                })
              }
              disabled={
                !!isLoadingSurvey ||
                !!isPatchingSurveyData ||
                form.hasErrors ||
                final
              }
            >
              {t('Finalize')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              startIcon={<AutorenewIcon />}
              onClick={() =>
                patchSurvey({
                  auth,
                  navigateSearch,
                  goBack: () => navigate(-1),
                  cpasurveyId: identifier,
                  snackbarMessage,
                  status: 'IN_REVIEW',
                })
              }
              disabled={
                !!isLoadingSurvey ||
                !!isPatchingSurveyData ||
                form.hasErrors ||
                final ||
                (!form.dirtyValues.has('note') &&
                  !hasReviewUpdates &&
                  !hasEdits)
              }
            >
              {t('Update')}
            </Button>
          </Grid>
          <Grid item xs={6} sm={3}>
            <DownloadFileButton id={identifier} />
            <Typography textAlign="center" sx={{ fontSize: '10px' }}>
              {t("CPA versions below 2.2 and version 3.0 don't support pdf")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default observer(SurveyInfoFooter);
