import Container from 'components/common/Container';

import Stack from 'components/common/Stack';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Button from 'components/common/Button';
import axios from 'axios';
import api from 'constants/api';
import useAuth from 'hooks/useAuth';
import TccInputTextField from 'components/common/TccInputTextField';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';
import Box from 'components/common/Box';
import AppLoading from 'components/common/AppLoading';
import { addHttpsOnUrl } from 'utils/https_on_url';
import useAdminCompany from 'hooks/useAdminCompany';

function DataServiceHtmlHarvester() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const { companyId } = useParams();

  const { isLoading, isError, refetch, data } = useAdminCompany(companyId);

  const [originalUrl, setOriginalUrl] = useState('');
  useEffect(() => {
    if (data) {
      const url = addHttpsOnUrl(data.website);
      setOriginalUrl(url);
    }
  }, [data]);

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const data = new FormData();

      const clicked_button = event.currentTarget;
      // select the scraper, but default to playwright
      const scraper_arg =
        clicked_button.getAttribute('data-scraper') || 'playwright';
      data.set('scraper', scraper_arg);

      data.set('type', 'WEB_SCREENING');
      if (companyId) {
        data.set('company', companyId);
      }
      data.set('url', originalUrl);
      await axios.post(
        `${api.url}/etl/jobs/create_with_url/`,
        data,
        auth.requestConfig
      );
      navigate('../..', { relative: 'path' });
    } catch (err) {
      // enable once live on backend:
      // window.Sentry?.captureException(err);
      console.error(err);
    }
  };

  return data ? (
    companyId ? (
      <Container disableGutters maxWidth="xs">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography sx={{ pt: 4 }} variant="h4">
              Html Harvester
            </Typography>
            <Alert severity="info">
              {t(
                'URL goes in 👉 a summary of collected html resources comes out.'
              )}
            </Alert>
          </Stack>
          <TccInputTextField
            title={t(`Website`)}
            value={originalUrl}
            onChange={(e) => setOriginalUrl(e.target.value)}
          />
          <Box />
          <Button
            variant="contained"
            color="primary"
            data-scraper="playwright"
            onClick={onSubmit}
          >
            Run with Playwright
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-scraper="scrapfly"
            onClick={onSubmit}
          >
            Run with Scrapfly
          </Button>
        </Stack>
      </Container>
    ) : (
      <div>no company state</div>
    )
  ) : (
    <AppLoading />
  );
}

export default DataServiceHtmlHarvester;
