import { useTranslation } from 'react-i18next';
import BusinessIcon from '@mui/icons-material/Business';
import Typography from 'components/common/Typography';

import Avatar from 'components/common/Avatar';
import { downloadFile } from 'utils/downloadFile';

import Stack from 'components/common/Stack/Stack';

import useUserCompany from 'hooks/useUserCompany';

import { getCountryName } from 'utils/getCountryName';
import Chip from 'components/common/Chip';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';
import api from 'constants/api';
import RadialBar from 'components/common/RadialBar/RadialBar';
import { getClimateScoreAsPercent } from 'utils/tools';
import { useCallback, useState } from 'react';
import colors from 'constants/colors';
import Box from 'components/common/Box';
import Button from 'components/common/Button';
import Card from 'components/common/Card';
import StatusDialog from 'components/common/EmailROIDialog';
import RFIDialog from 'components/common/UserDashboardAssesment/RFIDialog';
import { dateReadable } from 'utils/date';
import Divider from 'components/common/Divider';
import ClimateChoice from 'components/icons/ClimateChoice';
import LaunchOutlined from '@mui/icons-material/LaunchOutlined';
import EmojiEventsOutlined from '@mui/icons-material/EmojiEventsOutlined';
import DownloadIcon from 'components/icons/Download';

type Status = 'GOLD' | 'SILVER' | 'BRONZE';
const goldThreshold = 75;
const silverThreshold = 50;
const bronzeThreshold = 25;

const StatusBox = ({ status }: { status: Status }) => {
  const statusColors = colors[status.toLowerCase() as keyof typeof colors] as {
    start: string;
    end: string;
  };
  const { t } = useTranslation('common');

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      {/* Outer box with border radius for rounded corners */}
      <Box
        sx={{
          borderRadius: '24px', // Define the rounded corners here
          display: 'flex', // Flexbox for centering the inner content
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Inner box with the metallic effect */}
        <Box
          sx={{
            borderRadius: 'inherit', // Use inherit to maintain the outer border radius
            padding: '8px', // Slightly smaller padding
            color: colors.white,
            backgroundImage: `linear-gradient(145deg, ${statusColors.end}, ${statusColors.start}, ${statusColors.end}, ${statusColors.end}, ${statusColors.start}, ${statusColors.end})`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',

            transition: 'opacity 0.3s ease', // Smooth transition for the opacity effect
          }}
        >
          <ClimateChoice />
        </Box>
      </Box>
    </Box>
  );
};

const Component = ({
  scorecard,
}: {
  scorecard?: { overall: number; categories: { score: number }[] };
}) => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const auth = useAuth();

  const {
    userCompany,
    userCompanyCRC,
    isCPAexpired,
    expirationDateReadable,
    userCompanyCRCAssets,
  } = useUserCompany();

  const showCPAUpsellBanner =
    isCPAexpired === true || userCompanyCRC.status === undefined;

  const score = scorecard?.overall
    ? getClimateScoreAsPercent(scorecard.overall)
    : 0;

  const status =
    score >= goldThreshold
      ? 'GOLD'
      : score >= silverThreshold && score < goldThreshold
      ? 'SILVER'
      : score >= bronzeThreshold && score < silverThreshold
      ? 'BRONZE'
      : undefined;

  return (
    <Card>
      <Stack spacing={2} alignItems={'center'}>
        <Box width={'100%'}>
          <Typography variant="h5">{t('Your ClimateChoice Score')}</Typography>
        </Box>
        <Typography variant="body1">
          {t(
            "The ClimateChoice Score evaluates the company's climate performance and the quality of the supporting documents and data."
          )}
        </Typography>

        <Stack
          spacing={2}
          direction={{ xs: 'column', lg: 'row' }}
          sx={{ width: '100%' }}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Box width={270}>
            <RadialBar
              outOf100
              score={score}
              fontSize="28px"
              offsetY={12}
              radialOffsetX={-2}
              radialOffsetY={-5}
            />
          </Box>

          {status ? (
            <Box flex={1} alignItems={'center'} sx={{ width: '100%' }}>
              <Card borderColor={colors.secondary100} sx={{ padding: 2 }}>
                <Stack spacing={3} alignItems={'center'}>
                  <Stack
                    spacing={2}
                    pb={1.5}
                    direction={'row'}
                    width={'100%'}
                    sx={{ color: colors.neutral400 }}
                  >
                    <Typography flex={1} variant="body1">
                      {t('ClimateChoice Score')}
                    </Typography>
                    <EmojiEventsOutlined />
                  </Stack>
                  {userCompanyCRCAssets.badge ||
                  userCompanyCRCAssets.report ||
                  userCompanyCRCAssets.certificate ? (
                    <>
                      <Box>
                        <img
                          src={userCompanyCRCAssets.badge}
                          width={'120px'}
                          height={'120px'}
                        />
                      </Box>
                      <Stack
                        spacing={1}
                        sx={{
                          color: colors.secondary300,
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        {userCompanyCRCAssets.report ? (
                          <Button
                            fullWidth
                            color="secondary"
                            variant="text"
                            startIcon={<DownloadIcon />}
                            onClick={() =>
                              downloadFile(
                                `${userCompanyCRCAssets.report}`,
                                'ClimateChioceReport',
                                'pdf'
                              )
                            }
                          >
                            {t('Download Report')}
                          </Button>
                        ) : null}
                        {userCompanyCRCAssets.certificate ? (
                          <Button
                            fullWidth
                            color="secondary"
                            variant="text"
                            startIcon={<DownloadIcon />}
                            onClick={() =>
                              downloadFile(
                                `${userCompanyCRCAssets.certificate}`,
                                'ClimateChoiceCertificate',
                                'pdf'
                              )
                            }
                          >
                            {t('Download Certificate')}
                          </Button>
                        ) : null}
                        {userCompanyCRCAssets.badge ? (
                          <Button
                            fullWidth
                            color="secondary"
                            variant="text"
                            startIcon={<DownloadIcon />}
                            onClick={() =>
                              downloadFile(
                                `${userCompanyCRCAssets.badge}`,
                                'ClimateChoiceLabel',
                                'png'
                              )
                            }
                          >
                            {t('Download Label')}
                          </Button>
                        ) : null}
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack
                        spacing={2}
                        direction={'row'}
                        justifyContent={'center'}
                      >
                        {status === 'GOLD' ? <StatusBox status="GOLD" /> : null}
                        {status === 'SILVER' ? (
                          <StatusBox status="SILVER" />
                        ) : null}
                        {status === 'BRONZE' ? (
                          <StatusBox status="BRONZE" />
                        ) : null}
                      </Stack>
                      <Typography
                        display="flex"
                        alignItems="center"
                        variant={'h5'}
                        sx={{ color: colors.neutral500, textAlign: 'center' }}
                      >
                        {t('You qualify for {{status}}!', { status })}
                      </Typography>
                      <Box
                        sx={{
                          color: colors.secondary300,
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        <Button
                          fullWidth
                          color="warning"
                          variant="contained"
                          startIcon={<LaunchOutlined />}
                          onClick={() => setOpenStatus(true)}
                        >
                          {t('Upgrade to claim')}
                        </Button>
                      </Box>
                    </>
                  )}
                </Stack>
              </Card>
            </Box>
          ) : null}
        </Stack>
        {status ? <Box /> : null}
        {showCPAUpsellBanner ? (
          <>
            <Card backgroundColor={colors.warning100} noBoxShadow>
              <Stack spacing={1.5}>
                <Typography variant="h5" sx={{ color: colors.neutral600 }}>
                  {t('Provide more data to improve your ClimateChoice Score')}
                </Typography>
                <Typography
                  maxWidth={400}
                  variant="body1"
                  sx={{ color: colors.neutral600 }}
                >
                  {isCPAexpired === true && userCompanyCRC.expiration_date
                    ? t(
                        'Your Climate Performance Assessment expired on {{date}}',
                        {
                          date: expirationDateReadable,
                        }
                      )
                    : t(
                        'Start your Climate Performance Assessment and gain insights into your climate-related strengths, risks and opportunities for action.'
                      )}
                </Typography>
                <Box />
                <Button
                  size="large"
                  variant="contained"
                  color="warning"
                  onClick={() => setOpen(true)}
                >
                  {isCPAexpired === true && userCompanyCRC.expiration_date
                    ? t('Request New Assessment')
                    : t('Start Assessment')}
                </Button>
              </Stack>
            </Card>
            <Box />
          </>
        ) : null}

        <Divider sx={{ width: '100%' }} />
        <Box sx={{ width: '100%' }}>
          <Typography variant="body1" color={'text.secondary'}>
            {t('Last updated: {{date}}', {
              date: dateReadable(new Date().toString()),
            })}
          </Typography>
        </Box>
      </Stack>

      <StatusDialog
        open={openStatus}
        title={t('Starter Plan')}
        closeDialog={() => setOpenStatus(false)}
      />

      <RFIDialog
        open={open}
        closeDialog={() => {
          setOpen(false);
          return true;
        }}
        identifier={userCompany.id}
        companyName={userCompany.name}
        userDetails={auth.userDetails}
      />
    </Card>
  );
};

export default Component;
