import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Box from 'components/common/Box';
import Typography from '@mui/material/Typography';
import ConfidentialDataAccessTable from './ConfidentialDataAccessTable';
import Stack from 'components/common/Stack';
import Switch from 'components/common/Switch';
import useUserCompany from 'hooks/useUserCompany';
import useAuth from 'hooks/useAuth';

const UserCompanyVisibilityControls = () => {
  const { t } = useTranslation('common');
  const auth = useAuth();
  const { patchUserCompany, userCompany } = useUserCompany();

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('Access to climate data')}</Typography>
      <Box>
        <Typography
          variant={'caption2'}
          color={'neutral.contrastText'}
          mb={'10px'}
        >
          {t(
            `This setting controls who is able to access the data you have provided on this platform. Here you see exactly which companies can see your confidential data and which not. In addition, you can choose to share your confidential data with all your customers.`
          )}
        </Typography>
      </Box>
      <Switch
        label={'Accessible to all customers'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          patchUserCompany({
            auth,
            data: {
              default_customer_data_access_permissions: e.target.checked,
            },
          });
        }}
        checked={userCompany.default_customer_data_access_permissions}
      />
      <ConfidentialDataAccessTable
        allCustomers={userCompany.default_customer_data_access_permissions}
      />
    </Stack>
  );
};

export default observer(UserCompanyVisibilityControls);
