import { observer } from 'mobx-react-lite';

import Grid from '@mui/material/Grid';
import SearchVisibility from './SearchVisibility';
import ConfidentialDataAccess from './ConfidentialDataAccess';

const UserCompanyVisibilityControls = () => {
  return (
    <Grid container spacing={5}>
      <Grid item>
        <SearchVisibility />
      </Grid>
      <Grid item sm={12}>
        <ConfidentialDataAccess />
      </Grid>
    </Grid>
  );
};

export default observer(UserCompanyVisibilityControls);
