import React from 'react';

import CPASurveyQuestionData from './CPASurveyQuestionData';
import CPASurveyQuestionEdit from './CPASurveyQuestionEdit';

const CPASurveyQuestionSwitch = (props) => {
  return !props.final ? (
    <CPASurveyQuestionEdit {...props} />
  ) : (
    <CPASurveyQuestionData {...props} />
  );
};
export default CPASurveyQuestionSwitch;
