import React from 'react';
import { makeStyles } from '@mui/styles';

import IconButton from 'components/common/IconButton';
import { ExpandMoreIcon } from 'components/icons/mui_icons';
import { ExpandLessIcon } from 'components/icons/mui_icons';
import colors from 'constants/colors';

import Typography from 'components/common/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    color: colors.flintStone,
    marginBottom: theme.spacing(1.5),
  },
  titleContainer: {
    flex: '1 1 auto',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  titleContainerText: {
    flex: '1 1 auto',
    fontSize: '1em',
    color: colors.black,
  },
  titleContainerExpand: {
    flex: '0 0 auto',
  },
}));

const FilterItem = ({ children, title = '', divider = false }) => {
  const classes = useStyles({ divider });
  const [open, setOpen] = React.useState(true);

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography
          variant="subtitle2"
          color={colors.neutral600}
          sx={{ flex: '1 1 auto' }}
        >
          {title}
        </Typography>
        <div className={classes.titleContainerExpand}>
          {
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          }
        </div>
      </div>
      {open && <div>{children}</div>}
    </div>
  );
};

export default FilterItem;
