import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';
import SourceChannelChip from 'components/App/Routes/Company/CompanyDetailsPage/SourceChannelChip';

import { useTranslation } from 'react-i18next';
import EmptyTextField from 'components/common/EmptyTextField';
import { getLanguagePreference } from 'utils/browser';
import Typography from 'components/common/Typography';

export default function EmissionsDataTable({ data }: { data: any }) {
  const { t } = useTranslation('common');
  const rows = data;
  const columns: GridColDef[] = [
    { field: 'reporting_year', headerName: t('Year'), flex: 0, sortable: true },
    { field: 'variable', headerName: t('Variable'), flex: 1, sortable: false },
    {
      field: 'value',
      headerName: t('Value'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'source',
      headerName: t('Source'),
      sortable: false,
      flex: 0,
      renderCell: (params) => {
        return <SourceChannelChip channel={params.row.source_channel} />;
      },
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('Data unavailable'),
  };

  return !data ? (
    <EmptyTextField text={t('Data unavailable') + ''} />
  ) : (
    <div style={{ width: '100%' }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'reporting_year', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50]}
        pagination
        autoHeight
        localeText={localeText}
        sx={{ width: '100%' }}
        rows={rows}
        columns={columns}
        hideFooter={rows.length <= 10}
      />
    </div>
  );
}
