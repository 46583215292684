import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useAuth from 'hooks/useAuth';

import api from 'constants/api';

export default function useAdminCompanyDataPoints({
  companyId,
  jobId,
}: {
  companyId?: string;
  jobId?: string;
}) {
  const auth = useAuth();
  return useQuery({
    enabled: !!companyId || !!jobId,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0,
    queryKey: ['company-data-points', { companyId, jobId }],
    queryFn: async () => {
      const params = new URLSearchParams();
      if (companyId) {
        params.set('company', companyId);
      }
      if (jobId) {
        params.set('job', jobId);
      }
      const res = await axios.get(
        `${api.url}/admin/company/data-points/`,
        Object.assign(auth.requestConfig, { params })
      );
      return res.data;
    },
  });
}
