import React from 'react';
import { observer } from 'mobx-react-lite';
import useAuth from 'hooks/useAuth';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { getCountryName } from 'utils/getCountryName';

import Card from '@mui/material/Card';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LocationIcon from 'components/icons/Location';

import RatingLabel from 'components/common/CompanyRating/RatingLabel/RatingLabel';
import CompanyLogo from './CompanyLogo';
import TccPublishChip from 'components/common/TccPublishChip';

import Grid from 'components/common/Grid';

import colors from 'constants/colors';
import Typography from 'components/common/Typography';
import SaveFavoriteButton from 'components/common/SaveFavoriteButton';
import { useNavigate } from 'react-router-dom';
import useCompanies from 'hooks/useCompanies';

const useStyles = makeStyles((theme: any) => ({
  leftContainer: {
    padding: theme.spacing(1.5),
  },
  rightContainer: {
    padding: theme.spacing(1.5),
    backgroundColor: colors.neutral100,
    height: '100%',
  },
  iconText: {
    fontSize: '.85em',
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
    border: `1px solid ${theme.palette.divider}`,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    color: colors.neutral600,
  },
  itemIcon: {
    flex: '0 0 auto',
    maxHeight: '35px',
    marginRight: theme.spacing(1),
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  itemText: {
    flex: '1 1 auto',
    textTransform: 'uppercase',
    fontSize: '.75em',
  },
  itemTextBig: {
    flex: '1 1 auto',
    fontSize: '.85em',
    fontWeight: 'bold',
  },
  companyTags: {
    fontSize: '.85em',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  companyTagsList: {
    color: theme.palette.secondary.main,
  },
}));

const CompaniesListDataItem = ({ row }: { row: any }) => {
  const classes = useStyles();
  const auth = useAuth();
  let navigate = useNavigate();
  const { t } = useTranslation('common');
  const [isHover, setIsHover] = React.useState(false);

  const hasEmployeeRange = Object.keys(row).includes('employee_range');
  const hasTurnover = Object.keys(row).includes('turnover');
  const { companyFilterCategories } = useCompanies();

  return (
    <Card
      elevation={isHover ? 4 : 0}
      className={classes.paper}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        navigate(`/company/${row.slug}`);
      }}
    >
      <Grid container spacing={2}>
        <Grid item lg={9} md={8} xs={12}>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: '0 0 auto' }} className={classes.leftContainer}>
              <CompanyLogo title={row.name} logoSrc={row.logo} />
            </div>
            <div style={{ flex: '1 1 auto' }} className={classes.leftContainer}>
              <div className={classes.itemContainer}>
                <div className={classes.itemIcon}>
                  <LocationIcon />
                </div>
                <div
                  className={classes.itemTextBig}
                  style={{ marginLeft: '-4px' }}
                >
                  {row.city && row.country
                    ? [row.city, getCountryName(row.country)].join(',   ')
                    : getCountryName(row.country)}
                </div>
                <TccPublishChip
                  isAdmin={auth.hasAdminPermission}
                  published={row.published}
                  visibleIcon={false}
                />
              </div>
              <Typography
                variant={'h5'}
                color={'black'}
                sx={{ mt: '10px', mb: '10px' }}
              >
                {row.name}
              </Typography>
              {row.tcc_product_tags && (
                <div className={classes.companyTags}>
                  <Typography
                    variant="caption"
                    color={colors.neutral700}
                    sx={{ mr: '10px' }}
                  >
                    {t('Product & Services') + ':'}
                  </Typography>
                  <Typography variant="caption" color="primary.main">
                    {Object.values(row.tcc_product_tags).length === 0 ? (
                      <Typography variant="caption" color={colors.neutral400}>
                        {t('not available')}
                      </Typography>
                    ) : (
                      Object.values(row.tcc_product_tags).join(',  ')
                    )}
                  </Typography>
                </div>
              )}
              {
                //@ts-ignore
                companyFilterCategories?.sectors && (
                  <div className={classes.companyTags}>
                    <Typography
                      variant="caption"
                      color={colors.neutral700}
                      sx={{ mr: '10px' }}
                    >
                      {t('Sector') + ':'}
                    </Typography>
                    <Typography variant="caption" color="primary.main">
                      {row.sector ? (
                        // @ts-ignore
                        companyFilterCategories?.sectors[row.sector]
                      ) : (
                        <Typography variant="caption" color={colors.neutral400}>
                          {t('not available')}
                        </Typography>
                      )}
                    </Typography>
                  </div>
                )
              }
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} xs={12}>
          <div className={classes.rightContainer}>
            <div style={{ marginBottom: '8px' }}>
              <SaveFavoriteButton rowId={row.id}></SaveFavoriteButton>
            </div>
            {row.survey && (
              <div
                className={classes.itemContainer}
                style={{ marginBottom: '6px' }}
              >
                <div className={classes.itemIcon}>
                  <RatingLabel iconSrc={row.survey.badge} width="35px" />
                </div>
                <div className={classes.itemText}>
                  {t('Climate Choice')} {row.survey.label} {t('Rating')}
                </div>
              </div>
            )}
            {hasEmployeeRange && (
              <div className={classes.itemContainer}>
                <div className={classes.itemIcon} style={{ padding: '0 4px' }}>
                  <PeopleIcon />
                </div>
                <div className={classes.itemText}>
                  {row.employee_range || row.employee_range === 0
                    ? row.employee_range + ' ' + t('Employees')
                    : t('N/A')}
                </div>
              </div>
            )}
            {hasTurnover && (
              <div className={classes.itemContainer}>
                <div className={classes.itemIcon} style={{ padding: '0 4px' }}>
                  <BarChartIcon />
                </div>
                <div className={classes.itemText}>
                  {row.turnover || row.turnover === 0
                    ? row.turnover + ' ' + t('Eur')
                    : t('N/A')}
                </div>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default observer(CompaniesListDataItem);
