import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import Grid from 'components/common/Grid';
import AppLoading from 'components/common/AppLoading';

import CPASurveyQuestions from './CPASurveyQuestions';
import CPASurveyInfoFooter from './CPASurveyInfoFooter';

import useAuth from 'hooks/useAuth';
import useCPASurvey from '../store/useCPASurvey';

const CPASurveyInfo = ({ identifier }) => {
  const { loadSurveyData, isLoadingSurvey, hasLoadedSurvey, final } =
    useCPASurvey();
  const auth = useAuth();

  useEffect(() => {
    loadSurveyData({ auth, cpasurveyId: identifier });
  }, [identifier, auth, loadSurveyData]);
  return !hasLoadedSurvey || isLoadingSurvey ? (
    <AppLoading />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <CPASurveyQuestions final={final} identifier={identifier} />
      </Grid>
      <Grid item xs={12}>
        <CPASurveyInfoFooter identifier={identifier} final={final} />
      </Grid>
      <Grid item xs={12} />
    </Grid>
  );
};

export default observer(CPASurveyInfo);
