import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { getCountryName } from 'utils/getCountryName';

import Grid from 'components/common/Grid';
import Stack from 'components/common/Stack';
import Typography from 'components/common/Typography';
import { LocationOnIcon } from 'components/icons/mui_icons/index';

import EmptyTextField from 'components/common/EmptyTextField';
import colors from 'constants/colors';

type Props = {
  address: string | undefined;
  address2: string | undefined;
  country: string | undefined;
  city: string | undefined;
  zip_code: string | undefined;
};

function Address({ address, address2, country, city, zip_code }: Props) {
  const { t } = useTranslation('common');

  return (
    <Stack direction={'row'} marginTop={'20px'}>
      <LocationOnIcon sx={{ color: colors.neutral600 }} />
      <Grid marginLeft={'20px'}>
        <Typography variant="caption" color={colors.neutral600}>
          {t('Address')}
        </Typography>
        {address ? (
          <Grid>
            <Typography
              variant="body1"
              color={colors.neutral800}
              marginTop={'8px'}
            >
              {address}
            </Typography>
            <Typography
              variant="body1"
              color={colors.neutral800}
              marginTop={'8px'}
            >
              {address2}
            </Typography>
            <Typography
              variant="body1"
              color={colors.neutral800}
              marginTop={'8px'}
            >
              {zip_code} {city}
              {', '}
              {country ? getCountryName(country) : null}
            </Typography>
          </Grid>
        ) : (
          <EmptyTextField />
        )}
      </Grid>
    </Stack>
  );
}

export default observer(Address);
