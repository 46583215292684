import Dialog from 'components/common/Dialog';

import Container from '../Container';
import { useEmailROI } from 'hooks/useEmailROI';
import useUserCompany from 'hooks/useUserCompany';
import DialogTitle from '../DialogTitle';
import Toolbar from '../Toolbar';
import IconButton from '../IconButton';
import { CheckCircleIcon, CloseIcon } from 'components/icons/mui_icons';
import DialogContent from '@mui/material/DialogContent';
import Stack from '../Stack';
import Box from '../Box';
import Typography from '../Typography';
import { observer } from 'mobx-react-lite';
import Card from '../Card';
import colors from 'constants/colors';
import { useTranslation } from 'react-i18next';
import TccInputTextField from '../TccInputTextField';
import { useState } from 'react';
import Button from '../Button';
import CheckIcon from '@mui/icons-material/Check';
import useAuth from 'hooks/useAuth';

const RFIEmailDialog = ({
  open,
  closeDialog,
  title,
}: {
  open: boolean;
  closeDialog: () => void;
  title: string;
}) => {
  const auth = useAuth();
  const { userCompany } = useUserCompany();
  const { t } = useTranslation();
  const [info, setInfo] = useState('');
  const { mutate, isSuccess, isLoading } = useEmailROI();
  const onClose = () => {
    closeDialog();
  };

  const onClick = () => {
    if (userCompany.id) {
      const body =
        info +
        '\n\n' +
        Object.keys(auth.userDetails)
          .filter(
            (key) => !!auth.userDetails[key as keyof typeof auth.userDetails]
          )
          .map((key) => {
            return `${key}: ${
              auth.userDetails[key as keyof typeof auth.userDetails]
            }`;
          })
          .join('\n');
      console.log(body);
      mutate({
        subject: title,
        body,
        receiver_company: userCompany.id,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Toolbar disableGutters>
          <Stack
            spacing={2}
            direction={'row'}
            alignItems={'flex-start'}
            width={'100%'}
          >
            <Typography variant="h4" display={'flex'} flex={1}>
              {title}
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </DialogTitle>
      <DialogContent>
        <Stack direction={'row'} spacing={4}>
          <Card backgroundColor={colors.neutral200}>
            <Stack spacing={4}>
              <Typography variant={'h5'}>{t('Starter')}</Typography>
              <Typography variant={'body1'}>{t('1,990€ yearly')}</Typography>
              <Stack spacing={1}>
                <Stack spacing={2} direction={'row'}>
                  <CheckCircleIcon />
                  <Typography variant={'body1'}>
                    {t('Scoring label')}
                  </Typography>
                </Stack>
                <Stack spacing={2} direction={'row'}>
                  <CheckCircleIcon />
                  <Typography variant={'body1'}>
                    {t('Climate Performance Benchmark')}
                  </Typography>
                </Stack>
                <Stack spacing={2} direction={'row'}>
                  <CheckCircleIcon />
                  <Typography variant={'body1'}>
                    {t(
                      'Access company climate data directory (>50.000 data profiles)'
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Card>
          <Stack spacing={2}>
            <Typography variant={'body1'}>
              {t(
                'With the starter plan, you can download your status label, access benchmarks and browse data on more than 50,000 companies.'
              )}
            </Typography>
            <Box />
            <Box />
            <Box>
              <TccInputTextField
                multiline
                rows={4}
                placeholder={t('Add a request note (optional)')}
                disabled={isSuccess || isLoading}
                value={info}
                onChange={(e) => setInfo(e.target.value)}
              />
            </Box>
            <Box />
            <Button
              onClick={onClick}
              disabled={isSuccess || isLoading}
              startIcon={isSuccess ? <CheckIcon /> : <Box />}
              variant="contained"
              color="primary"
            >
              {t('Upgrade now')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(RFIEmailDialog);
